import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const checkboxesSlice = createSlice({
    name: 'checkbox',
    initialState,
    reducers: {
        setIsChecked: (state, action) => {
            const { name, value } = action.payload;
            if (name && value !== undefined) {
                state[name] = value;
            } else {
                // Если инпут пустой, то сбрасываем чекбоксы
                Object.keys(state).forEach((key) => {
                    state[key] = false;
                });
            }
        },
    },
});

export const { setIsChecked } = checkboxesSlice.actions;

export default checkboxesSlice.reducer;
