import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    count: 0,
};

const countDataSlice = createSlice({
    name: 'count',
    initialState,
    reducers: {
        setCount: (state, action) => {
            state.count = action.payload;
        },
    },
});

export const { setCount } = countDataSlice.actions;

export default countDataSlice.reducer;
