import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mainNewCards: [],
};

const cardsMainNewSlice = createSlice({
    name: 'cardsMainNew',
    initialState,
    reducers: {
        setCardsMainNew: (state, action) => {
            state.mainNewCards = action.payload;
        },
    },
});

export const { setCardsMainNew } = cardsMainNewSlice.actions;

export default cardsMainNewSlice.reducer;
