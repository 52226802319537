import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    card: {},
};

const singleCardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        setCard: (state, action) => {
            state.card = action.payload;
        },
    },
});

export const { setCard } = singleCardSlice.actions;

export default singleCardSlice.reducer;
