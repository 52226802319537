import { setEmail } from '../reducers/user-slice';
import { isClickEnter } from '../reducers/modal-slice';
import { setError } from '../reducers/error-slice';
import { setIsLoading } from '../reducers/preloader-slice';

export const checkResponse = (res) => {
    if (res.ok) {
        return res.json();
    }
    return Promise.reject(`Ошибка ${res.status}`);
};

// Обновление токена
/*export const refreshToken = () => {
  return fetch(`auth/jwt/create/`, {
      method: "POST",
      headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
      },
      body: JSON.stringify({
          token: localStorage.getItem('refresh')
      })
  }).then(checkResponse)
}

const fetchWithRefresh = async (url, options) => {
  try {
      const res = await fetch(url, options);
      return await checkResponse(res);
  } catch (err) {
      if (err.message === "jwt expired") {
          const refreshData = await refreshToken();
          localStorage.setItem("access", refreshData.access);
          localStorage.setItem("refresh", refreshData.refresh);
          options.headers.authorization = refreshData.access;
          const res = await fetch(url, options);
          return await checkResponse(res);
      } else {
          return Promise.reject(err);
      }
  }
};*/

export const login = (email, deviceId = '') => {
    const bodyWithoutAmplitude = {
        email: email,
    };

    const bodyWithAmplitude = {
        email: email,
        amplitude_device_id: deviceId,
    };

    return (dispatch) => {
        return fetch(`api/login-with-otp/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(deviceId ? bodyWithAmplitude : bodyWithoutAmplitude), // если используется mozilla, то данные в amplitude не отправляем
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Ошибка при авторизации');
                }
                return checkResponse(res);
            })
            .then(() => {
                dispatch(setEmail(email));
                dispatch(isClickEnter(true));
                dispatch(setError(''));
            })
            .catch((err) => {
                console.error(err);
                dispatch(setError(`На эту почту невозможно отправить код подтверждения`));
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
    };
};

export const activationWithCode = (otp, email, deviceId) => {
    const bodyWithoutAmplitude = {
        otp: otp,
        email: email,
    };

    const bodyWithAmplitude = {
        otp: otp,
        email: email,
        amplitude_device_id: deviceId,
    };

    return fetch(`api/validate-otp/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(deviceId ? bodyWithAmplitude : bodyWithoutAmplitude), // если используется mozilla, то данные в amplitude не отправляем
    });
    // .then((res) => {

    //   if (!res.ok) {
    //     throw new Error("Ошибка при проверке кода")
    //   }
    //   return checkResponse(res)
    // })

    // .then((res) => {
    //   // const subs = res.subscription

    //   // subs.map((element) => {
    //   //   element.subscription === "Unity"
    //   //     ? localStorage.setItem("subscriptionUnity", element.subscription)
    //   //     : localStorage.setItem("subscriptionIdeas", element.subscription)
    //   // })
    //   // localStorage.setItem("subscription", res.subscription)

    //   localStorage.setItem("token", res.token)
    //   dispatch(closeModal())
    //   dispatch(setAuthChecked(true))
    //   getUserInterestAPI()
    //   return res
    // })

    // .catch((err) => {
    //   console.error(err)
    // })
};

// Передать предпочтение пользователя на сервер
export const userInterestAPI = (userInterest) => {
    return fetch(`api/users/change-interest/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            interest: userInterest,
        }),
    })
        .then(checkResponse)
        .then(() => {})
        .catch((err) => {
            console.error(err);
        });
};

export const getPromoCodeData = (promo_code, product_id, price_rur, price_usd) => {
    return fetch(`api/users/apply-promo-code/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            promo_code: `${promo_code}`, //"TESTABSOLUT",
            product_id: product_id,
            price_rur: price_rur,
            price_usd: price_usd,
        }),
    });
    // .then((res) => {
    //   return res.json()
    // })
    // .then(checkResponse)
    // .catch((err) => {
    //   // console.error(err.error)
    // })
};

export const getPayment = (obj) => {
    return fetch(`api/payment/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            total_price: obj.total_price,
            promo_code_id: obj.promo_code_id,
            payment_type_id: obj.payment_type_id,
            product_id: obj.product_id,
        }),
    }).then((res) => {
        return res;
    });
    // .then(checkResponse)
    // .catch((err) => {
    //   // console.error(err.error)
    // })
};
export const setCancelSub = (obj) => {
    return fetch(`/api/users/canceled/?id=${obj.id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
        },
    }).then((res) => {
        return res.json();
    });
    // .then(checkResponse)
    // .catch((err) => {
    //   // console.error(err.error)
    // })
};
export const getUserProfileData = () => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
    };
    if (token) {
        headers.Authorization = `Token ${token}`;
    }
    return fetch(`api/users/profile`, {
        method: 'GET',
        headers,
    });
};

export const getReceptData = (val) => {
    // const token = localStorage.getItem("token")
    const headers = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    };
    // if (!!token) {
    //   headers.Authorization = `Token ${token}`
    // }
    return (
        fetch(`api/users/profile/subscriptions/${val}`, {
            method: 'GET',
            headers,
        })
            // .then((res) => {
            // return res
            // })
            .then(checkResponse)
    );
    // .then((res) => {

    //   return res
    // })

    // .catch((err) => {
    //   console.error(err)
    // })
};

export const getUserSubscriptionData = () => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    };
    if (token) {
        headers.Authorization = `Token ${token}`;
    }
    return (
        fetch(`api/users/profile/subscriptions`, {
            method: 'GET',
            headers,
        })
            // .then((res) => {
            //   return res
            // })
            .then(checkResponse)

            .catch((err) => {
                console.error(err);
            })
    );
};

// Получить предпочтения пользователя
const interestObj = {
    Россия: '?country_tag=1',
    Международные: '?country_tag=-1',
    Всё: '',
};

export const getUserInterestAPI = () => {
    const token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
    };
    if (token) {
        headers.Authorization = `Token ${token}`;
    }
    return (
        fetch(`api/users/profile/`, {
            method: 'GET',
            headers,
        })
            //   .then((res) => {
            //   return res
            // })
            .then(checkResponse)
            // .then((res) => {

            //   return res
            // })
            .then((res) => {
                const interesting = interestObj[res.interest];
                localStorage.setItem('country_tag', interesting);
            })
            .catch((err) => {
                console.log(err, 'in getUserInterestAPI');
            })
    );
};
// Выход из системы

export const logout = () => {
    // Удаление токена из локального хранилища
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    // Другие действия при выходе
};

export const getWebinarsData = () => {
    const token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
    };
    if (token) {
        headers.Authorization = `Token ${token}`;
    }

    return fetch(`/api/users/webinars/`, {
        method: 'GET',
        headers,
    });
};

export const setYmId = (ymIds) => {
    return fetch(`api/users/metrics/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            metrics_list: [ymIds],
        }),
    }).then((res) => {
        return res;
    });
    // .then(checkResponse)
    // .catch((err) => {
    //   // console.error(err.error)
    // })
};

export const getDataProfile = () => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
    };
    if (token) {
        headers.Authorization = `Token ${token}`;
    }
    return fetch(`api/users/profile/subscriptions`, {
        method: 'GET',
        headers,
    });
};
