import { useState } from 'react';

export const useInput = (initialVal) => {
    const [val, setVal] = useState(initialVal);

    const reset = () => {
        setVal(initialVal);
    };

    const bind = {
        value: val,
        onChange: (e) => setVal(e.target.value),
    };

    return { val, reset, bind };
};
