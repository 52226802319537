import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timerStatus: false,
};

const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setStatusTimer: (state, action) => {
            state.timerStatus = action.payload;
        },
    },
});

export const { setStatusTimer } = timerSlice.actions;
export default timerSlice.reducer;
