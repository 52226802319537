import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { emailCardAnalytic, getCardToFilter, getCompanyPointsApi } from '../../utils/api';
import { setIsLoading } from '../reducers/preloader-slice';
import { setAllFilterPoints, setCompanyPointsToCheckbox } from '../reducers/cards-filtred-for-checkbox-points-slice';
import { useActiveButtonCountryTagChoice } from './country-filter-context';

const ActiveFiltersContext = React.createContext();

export const useActiveFilters = () => {
    return useContext(ActiveFiltersContext);
};

export const ActiveFiltersProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(1);
    const [checkMAil, setCheckMAil] = useState(false);
    const [mailCompleted, setMailСompleted] = useState(false);
    const [filterCards, setFilterCards] = useState([]);
    const [filteredNews, setFilteredNews] = useState([]);
    const [isNextPageForNews, setIsNextPageForNews] = useState(null);
    const mounted = useRef(false);
    const mountedFiltersPoint = useRef(false);
    const globContextValue = useActiveButtonCountryTagChoice();
    const scrollPosition = useRef(0); // Реф для хранения позиции прокрутки
    const hasUrl = useRef(false);

    const setCardsFun = (cardData, newsData) => {
        setIsNextPageForNews(newsData.next);
        let newCard = cardData.results;
        let newNews = newsData.results;

        if (pageCount === 1) {
            setFilterCards(newCard);
            setFilteredNews(newNews);
        } else {
            if (newCard.length > 0) setFilterCards([...filterCards, ...newCard]);
            if (newNews.length > 0) setFilteredNews([...filteredNews, ...newNews]);
        }
    };

    const setMailSubscription = (mail) => {
        emailCardAnalytic(mail)
            .then((data) => {
                if (!data.ok) {
                    setCheckMAil(true);
                } else {
                    setMailСompleted(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const setAllGalleryCards = (
        orderingPoints,
        categoryPoints,
        countryPoints = '',
        sectorPoints,
        companyPoints,
        analysisPoints,
        subscriptionPoints,
        bondsPoints,
        changePortfolios
    ) => {
        if (localStorage.getItem('country_tag') === '?country_tag=-1' && countryPoints === '') {
            countryPoints = '&country_tag=-1';
        }
        if (localStorage.getItem('country_tag') === '?country_tag=1' && countryPoints === '') {
            countryPoints = '&country_tag=1';
        }
        const analyticsPromise = getCardToFilter(
            `${orderingPoints}${categoryPoints}${countryPoints}${sectorPoints}${companyPoints}${analysisPoints}${subscriptionPoints}${bondsPoints}${changePortfolios}&page_category=2`,
            pageCount
        );

        const newsPromise = getCardToFilter(
            `${orderingPoints}${categoryPoints}${countryPoints}${sectorPoints}${companyPoints}${analysisPoints}${subscriptionPoints}${bondsPoints}${changePortfolios}&page_category=1`,
            pageCount
        );

        /*        Promise.all([analyticsPromise, newsPromise])
            .then(([analyticsData, newsData]) => {
                setCardsFun(analyticsData, newsData);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });*/

        Promise.allSettled([analyticsPromise, newsPromise])
            .then((results) => {
                const analyticsData = results[0].status === 'fulfilled' ? results[0].value : [];
                const newsData = results[1].status === 'fulfilled' ? results[1].value : [];
                setCardsFun(analyticsData, newsData);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
    };
    const setCompanyForFilters = (
        countryPointsForApi,
        sectorPointsForApi,
        categoryPointsForApi,
        analysisPoints,
        subscriptionPoints,
        bondsPoints,
        changePortfolios,
        changeNews
    ) => {
        const userInterest = localStorage.getItem('country_tag');
        if (countryPointsForApi === '' && userInterest !== null) {
            countryPointsForApi = userInterest;
        }
        if (hasUrl.current) {
            countryPointsForApi = ''; // добавить проверку, уходил ли человек со страницы
        }
        getCompanyPointsApi(
            `${countryPointsForApi}${sectorPointsForApi}${changeNews ? '&page_category=2' : ''}${
                bondsPoints ? '&bonds=true' : ''
            }${analysisPoints ? '&analysis=true' : ''}${subscriptionPoints ? '&subscription=2,4,5' : ''}${changePortfolios ? '&change_in_portfolios=true' : ''}`
        ).then((data) => {
            if (!globContextValue.mountedFilters.current) {
                dispatch(setAllFilterPoints(data));
                globContextValue.mountedFilters.current = true;
            }

            dispatch(setCompanyPointsToCheckbox(data));
        });
    };
    return (
        <ActiveFiltersContext.Provider
            value={{
                pageCount,
                setPageCount,
                setAllGalleryCards,
                setCompanyForFilters,
                setMailSubscription,
                checkMAil,
                setCheckMAil,
                mailСompleted: mailCompleted,
                setMailСompleted,
                filterCards,
                filteredNews,
                isNextPageForNews,
                setFilterCards,
                scrollPosition,
                mounted,
                mountedFiltersPoint,
                hasUrl,
            }}
        >
            {children}
            <Outlet />
        </ActiveFiltersContext.Provider>
    );
};
