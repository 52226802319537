import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    clickEnter: false,
    userDelete: false,
};

const modalSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        isOpenModal: (state, action) => {
            state.isOpen = action.payload;
        },
        isClickEnter: (state, action) => {
            state.clickEnter = action.payload;
        },
        isUserDelete: (state, action) => {
            state.userDelete = action.payload;
        },
        closeModal: (state, action) => {
            state.isOpen = action.payload;
            state.clickOrder = action.payload;
            state.clickEnter = action.payload;
        },
    },
});

export const { isOpenModal, closeModal, isClickEnter, isUserDelete } = modalSlice.actions;

export default modalSlice.reducer;
