import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    textInput: '',
};

const textInputSlice = createSlice({
    name: 'textInput',
    initialState,
    reducers: {
        setTextInput: (state, action) => {
            state.textInput = action.payload;
        },
    },
});

export const { setTextInput } = textInputSlice.actions;

export default textInputSlice.reducer;
