import { useContext, useState } from 'react';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCardsMainAnalytics, getLesson, getThemesCard, getThemesEducationCards } from '../../utils/api';
import { setIsLoading } from '../reducers/preloader-slice';
import { useActiveButtonCountryTagChoice } from './country-filter-context';
import { setCardsMainAnalytics } from '../reducers/cards-main-analytics-slice';

const EducationContext = React.createContext();

export const useEducationContext = () => {
    return useContext(EducationContext);
};

export const EducationProvider = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [themesData, setThemesData] = useState([]);
    const [lessonsData, setLessonsData] = useState([]);
    const [lessonCardData, setLessonCardData] = useState([]);
    const val = useActiveButtonCountryTagChoice();

    const getCardsMainAnalyticsContext = (slug) => {
        getCardsMainAnalytics(slug)
            .then((res) => {
                dispatch(setCardsMainAnalytics(res.results));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
    };

    const getThemesDataApi = () => {
        getThemesEducationCards()
            .then(async (res) => {
                const path = {
                    404: '/not-found',
                    // 401: "/authorization",
                    403: '/error-403',
                    500: '/error-500',
                };
                if (res.ok) {
                    const data = await res.json();
                    setThemesData(data.results);
                } else if (res.status in path) {
                    val.setBackendErrorCheck(true);
                    navigate(path[res.status]);
                } else {
                    return res.json();
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
        // .then((res) => {
        //   dispatch(setThemesCards(res.results))
        //   dispatch(setLessonsCards(res.results.map((element) => element.lessons)))
        // })
    };

    const getLessonsDataApi = (slug) => {
        getThemesCard(slug)
            .then(async (res) => {
                const path = {
                    // 404: "/not-found",
                    // 401: "/authorization",
                    403: '/error-403',
                    500: '/error-500',
                };
                if (res.ok) {
                    const data = await res.json();
                    setLessonsData(data);
                } else if (res.status in path) {
                    navigate(path[res.status]);
                } else {
                    return res.json();
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
        // .then((res) => {
        //   dispatch(setCardTheme(res))
        //   dispatch(setLessonsCards(res.lessons))
        // })
    };

    const getLessonCardDataApi = (name) => {
        getLesson(name)
            .then(async (res) => {
                const path = {
                    // 404: "/not-found",
                    // 401: "/authorization",
                    403: '/error-403',
                    500: '/error-500',
                };
                if (res.ok) {
                    const data = await res.json();
                    setLessonCardData(data);
                } else if (res.status in path) {
                    navigate(path[res.status]);
                } else {
                    return res.json();
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
    };

    // const dispatch = useDispatch()
    // const navigate = useNavigate()
    return (
        <EducationContext.Provider
            value={{
                themesData,
                getThemesDataApi,
                lessonsData,
                getLessonsDataApi,
                lessonCardData,
                getLessonCardDataApi,
                getCardsMainAnalyticsContext,
            }}
        >
            {/* children */}
            <Outlet />
        </EducationContext.Provider>
    );
};
