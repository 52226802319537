import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICompanyInfo, IScreenerState } from '../../types/screener/screejer-information.types';

// Начальное состояние
const initialState: IScreenerState = {
    information: null,
    loading: true,
    error: false,
};

// Определяем типизированный thunk
export const getInformationScreenerPage = createAsyncThunk<ICompanyInfo, string>(
    'information/getInformationScreenerPage',
    async (company) => {
        const res = await axios(`/api/companies/get-card/?ticker_name=${company.toUpperCase()}`);
        return res.data.info as ICompanyInfo;
    }
);

// Создаем слайс
const screenerSlice = createSlice({
    name: 'screener',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInformationScreenerPage.pending, (state) => {
            state.loading = true;
            state.error = false; // сбрасываем ошибку при новом запросе
        });
        builder.addCase(getInformationScreenerPage.fulfilled, (state, action: PayloadAction<ICompanyInfo>) => {
            state.information = action.payload;
            state.loading = false;
        });
        builder.addCase(getInformationScreenerPage.rejected, (state) => {
            state.error = true;
            state.loading = false;
        });
    },
});

// Экспортируем actions и reducer
export const { actions: screenerActions } = screenerSlice;
export const { reducer: screenerReducer } = screenerSlice;
