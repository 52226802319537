import { ConfigProvider } from 'antd';

export const AntdThemeProvider = ({ children }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        itemColor: 'var(--primary-color)',
                        titleFontSize: 16,
                        itemActiveColor: '#047337',
                        itemSelectedColor: '#047337',
                        itemHoverColor: 'var(--primary-color)',
                        inkBarColor: '#047337',
                        cardPadding: '60px 200px',
                    },
                },
                token: {
                    fontFamily: 'Montserrat, sans-serif',
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};
