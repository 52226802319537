import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tables: [],
    tableName: '',
    subForFetch: '',
};

const tablesSlice = createSlice({
    name: 'tables',
    initialState,
    reducers: {
        setTables: (state, action) => {
            state.tables = action.payload;
        },

        setTableName: (state, action) => {
            state.tableName = action.payload;
        },

        setSubForFetch: (state, action) => {
            state.subForFetch = action.payload;
        },
        setClearTable: (state) => {
            state.tables = [];
        },
    },
});

export const { setTables, setTableName, setSubForFetch, setClearTable } = tablesSlice.actions;

export default tablesSlice.reducer;
