import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isButtonDisabled: false,
};

const buttonDisabledSlice = createSlice({
    name: 'button-disabled',
    initialState,
    reducers: {
        setIsButtonDisabled: (state, action) => {
            state.isButtonDisabled = action.payload;
        },
    },
});

export const { setIsButtonDisabled } = buttonDisabledSlice.actions;

export default buttonDisabledSlice.reducer;
