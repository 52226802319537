import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderingVision: 'Сначала новые',
    checkBoxChoice: [],
    selectCategoryPoint: [],
    selectCountryPoint: [],
    selectSectorPoint: [],
    selectCompanyPoint: [],
    selectAnalysisPoint: false,
    selectSubscriptionPoint: false,
    selectBondsPoint: false,
    selectChangeInPortfolios: false,
    selectChangeInNews: false,
};

const searchSectionVisionSlice = createSlice({
    name: 'searchSectionVision',
    initialState,
    reducers: {
        setOrderingVision: (state, action) => {
            state.orderingVision = action.payload;
        },
        setSelectCategoryPoint: (state, action) => {
            state.selectCategoryPoint = action.payload;
        },
        setSelectCountryPoint: (state, action) => {
            state.selectCountryPoint = action.payload;
        },
        setSelectSectorPoint: (state, action) => {
            state.selectSectorPoint = action.payload;
        },
        setSelectCompanyPoint: (state, action) => {
            state.selectCompanyPoint = action.payload;
        },
        setCheckBoxChoice: (state, action) => {
            state.checkBoxChoice = action.payload;
        },
        setClearAllVisionSection: (state) => {
            state.orderingVision = 'Сначала новые';
            state.checkBoxChoice = [];
            state.selectCategoryPoint = [];
            state.selectCountryPoint = [];
            state.selectSectorPoint = [];
            state.selectCompanyPoint = [];
            state.selectAnalysisPoint = false;
            state.selectSubscriptionPoint = false;
            state.selectBondsPoint = false;
            state.selectChangeInPortfolios = false;
            state.selectChangeInNews = false;
        },
        setClearSelectCompanyPoint: (state) => {
            state.selectCompanyPoint = [];
        },
        setSelectAnalysisPoint: (state, action) => {
            state.selectAnalysisPoint = action.payload;
        },
        setSelectSubscriptionPoint: (state, action) => {
            state.selectSubscriptionPoint = action.payload;
        },
        setSelectBondsPoint: (state, action) => {
            state.selectBondsPoint = action.payload;
        },
        setSelectChangeInPortfolios: (state, action) => {
            state.selectChangeInPortfolios = action.payload;
        },
        setSelectChangeInNews: (state, action) => {
            state.selectChangeInNews = action.payload;
        },
        seturlParams: (state, action) => {
            state.selectCountryPoint = action.payload.country;
            state.selectCategoryPoint = action.payload.category;
            state.selectSectorPoint = action.payload.selector;
            state.selectCompanyPoint = action.payload.company;
            state.selectAnalysisPoint = action.payload.analysis;
            state.selectSubscriptionPoint = action.payload.unity;
            state.selectBondsPoint = action.payload.bonds;
            state.selectChangeInPortfolios = action.payload.portfolios;
            state.selectChangeInNews = action.payload.news;
        },
    },
});

export const {
    setOrderingVision,
    setSelectCategoryPoint,
    setCheckBoxChoice,
    setSelectCountryPoint,
    setSelectSectorPoint,
    setSelectCompanyPoint,
    setClearAllVisionSection,
    setClearSelectCompanyPoint,
    setSelectAnalysisPoint,
    setSelectSubscriptionPoint,
    setSelectBondsPoint,
    seturlParams,
    setSelectChangeInPortfolios,
    setSelectChangeInNews,
} = searchSectionVisionSlice.actions;

export default searchSectionVisionSlice.reducer;
