import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ActiveButtonProvider } from '@services/context/active-button-context';
import { ActiveCountryTagProvider } from '@services/context/country-filter-context';
import { ActiveFiltersProvider } from '@services/context/active-filters-context';
import { ProfileProvider } from '@services/context/profile-context';
import { PageInfoProvider } from '@services/context/page-info-context';
import { WebinarProvider } from '@services/context/webinar-context';
import { isClickEnter, isUserDelete } from '@services/reducers/modal-slice';
import { EducationProvider } from '@services/context/education-context.jsx';
import { TwoLevelMenuOther } from './layout-main/app-header-main/two-level-menu/two-level-menu';
import { PopUpTimeoutAnalitycs } from '@common/pop-ups/pop-up-timeout/pop-up-timeout-analitycs/pop-up-timeout-analitycs';
import { PopUpTimeOutTables } from '@common/pop-ups/pop-up-timeout/pop-up-timeout-tables/pop-up-timeout-tables';
import { PopUpTimeoutEmail } from '@common/pop-ups/pop-up-timeout/pop-up-timeout-email/pop-up-timeout-email';
import { AntdThemeProvider } from './antd-theme-provider/antd-theme-provider';
import { ExternalRedirect } from './external-redirect/external-redirect';
import { PreloaderMain } from './preloader-main/preloader-main';
import AppHeaderMain from './layout-main/app-header-main/app-header-main';
import FooterMain from './layout-main/footer-main/footer-main';
import {
    AboutCard,
    AboutUs,
    AnalyticsAndNews,
    AppMain,
    AuthorizationPage,
    BlogForLink,
    BlogPage,
    Education,
    Error403,
    Error500,
    Glossary,
    LayoutMain,
    Lesson,
    LessonsPage,
    MarketMap,
    NoAccess,
    NoAuthProfile,
    PageForAddInfo,
    PageInfo,
    PageInfoUrl,
    ProductSub,
    Profile,
    ScreenerMain,
    ScreenerPage,
    TableNew,
    Tables,
    TariffsPage,
    Webinars,
} from './lazy-loaded-pages';
import styles from './app.module.css';

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
    const [showTimeoutPopupFirst, setShowTimeoutPopupFirst] = useState(false);
    const [showTimeoutPopupSecond, setShowTimeoutPopupSecond] = useState(false);
    const [showTimeoutPopupThird, setShowTimeoutPopupThird] = useState(false);

    useEffect(() => {
        if (window.ym) {
            window.ym(96742836, 'hit', location.pathname + location.search);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname !== '/authorization') {
            dispatch(isUserDelete(false));
            dispatch(isClickEnter(false));
        }
        if (
            localStorage.getItem('token') &&
            location.pathname !== '/authorization' &&
            location.pathname !== '/profile' &&
            location.pathname !== '/not-found'
        ) {
            localStorage.setItem('location', location.pathname);
        }
    }, [location.pathname]);

    useEffect(() => {
        let timer;

        // Проверка на поискового бота
        const userAgent = navigator.userAgent.toLowerCase();
        const isSearchBot =
            userAgent.includes('yandex') || userAgent.includes('google') || userAgent.includes('yadirectfetcher');

        if (isSearchBot) return; // Если это поисковый бот, ничего не делаем
        if (localStorage.getItem('noPopUp')) return;
        if (isAuthChecked) return;

        if (!isAuthChecked && location.pathname !== '/authorization' && location.pathname !== '/profile') {
            const firstVisit = localStorage.getItem('firstVisit');
            const secondVisit = localStorage.getItem('secondVisit');
            const thirdVisit = localStorage.getItem('thirdVisit');

            if (!firstVisit && !secondVisit && !thirdVisit) {
                timer = setTimeout(() => {
                    setShowTimeoutPopupFirst(true);
                    localStorage.setItem('firstVisit', 'firstVisit');
                }, 15000);
            } else if (firstVisit && !secondVisit && !thirdVisit) {
                timer = setTimeout(() => {
                    setShowTimeoutPopupSecond(true);
                    localStorage.setItem('secondVisit', 'secondVisit');
                }, 15000);
            } else if (firstVisit && secondVisit && !thirdVisit) {
                timer = setTimeout(() => {
                    setShowTimeoutPopupThird(true);
                    localStorage.setItem('thirdVisit', 'thirdVisit');
                }, 15000);
            }
            if (thirdVisit) {
                setShowTimeoutPopupFirst(false);
                setShowTimeoutPopupSecond(false);
                setShowTimeoutPopupThird(false);
            }
        }

        return () => clearTimeout(timer);
    }, [isAuthChecked, location.pathname]);

    useEffect(() => {
        if (window.ym) {
            if (showTimeoutPopupFirst) {
                window.ym(96742836, 'reachGoal', 'popup-our-analysis');
            }
            if (showTimeoutPopupSecond) {
                window.ym(96742836, 'reachGoal', 'popup-promo-start');
            }
            if (showTimeoutPopupThird) {
                window.ym(96742836, 'reachGoal', 'popup-email');
            }
        }
    }, [showTimeoutPopupFirst, showTimeoutPopupSecond, showTimeoutPopupThird]);

    useEffect(() => {
        if (showTimeoutPopupFirst || showTimeoutPopupSecond || showTimeoutPopupThird) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [showTimeoutPopupFirst, showTimeoutPopupSecond, showTimeoutPopupThird]);

    return (
        <HelmetProvider>
            <AntdThemeProvider>
                {showTimeoutPopupFirst && <PopUpTimeoutAnalitycs setShowTimeoutPopupFirst={setShowTimeoutPopupFirst} />}
                {showTimeoutPopupSecond && <PopUpTimeOutTables setShowTimeoutPopupSecond={setShowTimeoutPopupSecond} />}
                <ActiveFiltersProvider>
                    {showTimeoutPopupThird && <PopUpTimeoutEmail setShowTimeoutPopupThird={setShowTimeoutPopupThird} />}
                </ActiveFiltersProvider>
                <ActiveCountryTagProvider className={styles.app}>
                    <AppHeaderMain />
                    <Suspense fallback={<PreloaderMain />}>
                        <Routes>
                            <Route path="/" element={<LayoutMain />}>
                                <Route path="/authorization" element={<AuthorizationPage />} />

                                <Route element={<WebinarProvider />}>
                                    <Route path="/webinars" element={<Webinars />} />
                                </Route>

                                <Route element={<ActiveFiltersProvider />}>
                                    <Route path="/analytics-and-news/" element={<AnalyticsAndNews />} />
                                    <Route path="/analytics-and-news/:slug" element={<AboutCard />} />
                                    <Route path="/about-us" element={<AboutUs />} />
                                </Route>

                                <Route element={<EducationProvider />}>
                                    <Route path="/education" element={<Education />} />
                                    <Route path="/education/:slug" element={<LessonsPage />} />
                                    <Route path="/lessons/:slug" element={<Lesson />} />
                                </Route>

                                <Route element={<ProfileProvider />}>
                                    <Route index element={<AppMain />} />
                                    <Route path="/profile" element={isAuthChecked ? <Profile /> : <NoAuthProfile />} />
                                    <Route path="/products/:slug" element={<ProductSub />} />
                                    <Route path="/tariffs" element={<TariffsPage />} />
                                </Route>

                                <Route element={<ActiveButtonProvider />}>
                                    <Route path="/" element={<TwoLevelMenuOther />} />
                                    <Route path="/tables/:slug" element={<Tables />} />
                                    <Route path="/tables/:subs/:sub/:slug" element={<TableNew />} />
                                    <Route path="/polezno-investoru/" element={<BlogPage />} />
                                    <Route path="/polezno-investoru/:slug" element={<BlogForLink />} />
                                </Route>

                                <Route element={<PageInfoProvider />}>
                                    <Route path="/info" element={<PageInfo />} />
                                    <Route path="/info/:slug" element={<PageInfoUrl />} />
                                </Route>

                                <Route path="/market-map" element={<MarketMap />} />
                                <Route path="/glossary" element={<Glossary />} />
                                <Route path="/screener/:slug" element={<ScreenerPage />} />
                                <Route path="/screener" element={<ScreenerMain />} />

                                <Route path="/nv342390n7n903n90mv39082380v23v48" element={<PageForAddInfo />} />

                                <Route path="/noaccess" element={<NoAccess />} />
                                <Route path="/error-500" element={<Error500 />} />
                                <Route path="/error-403" element={<Error403 />} />
                                <Route path="*" element={<ExternalRedirect />} />
                            </Route>
                        </Routes>
                    </Suspense>
                    <FooterMain />
                </ActiveCountryTagProvider>
            </AntdThemeProvider>
        </HelmetProvider>
    );
}

export default App;
