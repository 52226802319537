import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCountryPoints } from '../reducers/cards-filtred-slice';

const CountryFilterContext = React.createContext();

export const useActiveButtonCountryTagChoice = () => {
    return useContext(CountryFilterContext);
};

export const ActiveCountryTagProvider = ({ children }) => {
    const dispatch = useDispatch();

    const [activeButton, setActiveButton] = useState(false);
    const [popUpChoice, setPopUpChoice] = useState('');
    const [popUpExitVision, setPopUpExitVision] = useState(false);
    const [popUpCookieVision, setPopUpCookieVision] = useState(true);
    const [backendErrorCheck, setBackendErrorCheck] = useState(false);
    const mountedFilters = useRef(false);

    const toggleVisible = (val) => {
        setActiveButton(val);
    };
    const toggleCountry = (val) => {
        if (val === '?country_tag=-1') {
            dispatch(setCountryPoints([{ id: -1 }]));
        }
        if (val === '?country_tag=1') {
            dispatch(setCountryPoints([{ id: 1 }]));
        }

        setPopUpChoice(val);
    };
    // const setAllPointsToFilters = () => {
    //   getFilterPointsApi(popUpChoice).then(
    //     (data) => {

    //       dispatch(setAllFilterPoints(data))
    //     }
    //   )
    // }
    return (
        <CountryFilterContext.Provider
            value={{
                visible: activeButton,
                popUpChoice: popUpChoice,
                toggleCountry,
                toggleVisible,
                // setAllPointsToFilters,
                popUpExitVision,
                setPopUpExitVision,
                popUpCookieVision,
                setPopUpCookieVision,
                backendErrorCheck,
                setBackendErrorCheck,
                mountedFilters,
            }}
        >
            {children}
        </CountryFilterContext.Provider>
    );
};
