import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICompaniesState, IRussianCompany } from '../../types/screener/russian-companies.types';

export const fetchRussianCompanies = createAsyncThunk<IRussianCompany[]>(
    'companies/fetchRussianCompanies',
    async () => {
        const response = await axios.get('/api/companies/get-russian-companies/');
        return response.data;
    }
);

const initialState: ICompaniesState = {
    data: [],
    isLoading: true,
    error: null,
};

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRussianCompanies.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchRussianCompanies.fulfilled, (state, action: PayloadAction<IRussianCompany[]>) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchRussianCompanies.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Error loading data';
            });
    },
});

export default companiesSlice.reducer;
