import React, { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    getUserProfileData,
    getUserSubscriptionData,
    getReceptData,
    getPromoCodeData,
    getPayment,
    setCancelSub,
} from '../actions/user';
import { useDispatch } from 'react-redux';
import { isClickEnter, isUserDelete } from '../reducers/modal-slice';
import { setAuthChecked, setUser } from '../reducers/user-slice';
import { getCardsMainAnalytics } from '../../utils/api';
import { setCardsMainAnalytics } from '../reducers/cards-main-analytics-slice';
import { setIsLoading } from '../reducers/preloader-slice';

const ProfileContext = React.createContext();

export const useProfile = () => {
    return useContext(ProfileContext);
};

export const ProfileProvider = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [userSubInfo, setUserSubInfo] = useState([]);
    const [receptInfo, setReceptInfo] = useState(false);
    const [totalRubles, setTotalRubles] = useState('');
    const [bonusMonth, setBonusMonth] = useState('');
    const [promoCodeData, setPromoCodeData] = useState(false);
    const [bankUrl, setBankUrl] = useState(false);
    const [popUpActive, setPopUpActive] = useState(false);
    const [cancelSubscription, setCancelSubscription] = useState(false);
    const [cancelSubErrorMessage, setCancelSubErrorMessage] = useState(false);
    const [isReceptInfoLoading, setIsReceptInfoLoading] = useState(false);

    const logout = () => {
        navigate('/authorization');
        localStorage.removeItem('token');
        localStorage.removeItem('subscription');
        dispatch(setUser(null));
        dispatch(setAuthChecked(false));
        dispatch(isUserDelete(true));
        dispatch(isClickEnter(false));
    };

    const userProfileData = () => {
        getUserProfileData()
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    setUserData(data);
                } else {
                    return res.json();
                }
            })
            .then((res) => {
                if (!!res && res.detail === 'Недопустимый токен.') {
                    logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
                }
            });
        //   .then((response) => {
        //     // if (!response.ok && response.status === 401) {

        //     //   localStorage.removeItem("token")
        //     //   navigate("/")
        //     //   // dispatch(isOpenModal(true))
        //     //   dispatch(isUserDelete(true))
        //     //   dispatch(isClickEnter(false))
        //     // }
        //     return response.json()
        // })

        //   .then((res) => {
        //     setUserData(res)
        //   })
    };

    const userSubscriptionData = () => {
        getUserSubscriptionData().then((res) => {
            setUserSubInfo(res);
            return res;
        });
    };
    const receptData = (type) => {
        /*    getReceptData(type).then((res) => {
      setReceptInfo(res.options.sort((a, b) => a.id - b.id));
    });*/

        setIsReceptInfoLoading(true); // Устанавливаем isLoading в true перед запросом
        getReceptData(type)
            .then((res) => {
                setReceptInfo(res.options.sort((a, b) => a.id - b.id));
            })
            .finally(() => {
                setIsReceptInfoLoading(false); // Устанавливаем isLoading в false после завершения запроса
            });
    };

    const promoCodeInitialApi = ({ promo_code, product_id, price_rur, price_usd }) => {
        if (promo_code.length !== 0) {
            getPromoCodeData(promo_code, product_id, price_rur, price_usd)
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res.error) {
                        setPromoCodeData(res.error);
                    } else {
                        setPromoCodeData(res);
                        setTotalRubles(res.totalRubles);
                        if (res.bonus_period) {
                            setBonusMonth(res.bonus_period);
                        }
                    }
                });
        }
    };

    const paymentTogglePost = (obj) => {
        getPayment(obj)
            .then((response) => {
                if (response.ok && response.status === 200) {
                    return response.json();
                }
            })
            .then((res) => {
                setBankUrl(res.payment_url);
                window.location.href = res.payment_url;
            });
    };
    const subscriptionCanceled = (sub) => {
        setCancelSub(sub).then((response) => {
            if (response.error) {
                // setPromoCodeData(res.error)
                setCancelSubErrorMessage(response.error);
            } else {
                // if (response.ok && response.status === 200) {
                setCancelSubscription(true);
            }
        });
    };

    const getCardsMainAnalyticsContext = () => {
        getCardsMainAnalytics()
            .then((res) => {
                dispatch(setCardsMainAnalytics(res.results));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });
    };

    return (
        <ProfileContext.Provider
            value={{
                userProfileData,
                userData,
                userSubscriptionData,
                userSubInfo,
                receptData,
                receptInfo,
                isReceptInfoLoading,
                promoCodeInitialApi,
                promoCodeData,
                setPromoCodeData,
                totalRubles,
                setTotalRubles,
                bonusMonth,
                setBonusMonth,
                paymentTogglePost,
                bankUrl,
                popUpActive,
                setPopUpActive,
                subscriptionCanceled,
                cancelSubscription,
                setCancelSubscription,
                cancelSubErrorMessage,
                setCancelSubErrorMessage,
                getCardsMainAnalyticsContext,
                // pageCount:pageCount,
                // setNextPage
            }}
        >
            {/* children */}
            <Outlet />
        </ProfileContext.Provider>
    );
};
