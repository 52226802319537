// features/shareList/shareListSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getShareList } from '../../utils/api';

// Асинхронный thunk для получения данных
export const fetchShareList = createAsyncThunk('shareList/fetchShareList', async (_, { rejectWithValue }) => {
    try {
        const res = await getShareList();
        if (res.ok) {
            return await res.json();
        } else {
            return rejectWithValue('Error fetching data');
        }
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const shareListSlice = createSlice({
    name: 'shareList',
    initialState: {
        sectors: [],
        companies: [],
        loading: false,
        error: null,
        scrollTop: 0,
        selectedSector: { id: 0, name: 'Отрасль' },
        selectedCategory: { id: 'all', name: 'Все' },
    },
    reducers: {
        setScrollTop: (state, action) => {
            state.scrollTop = action.payload;
        },
        setSelectedSector: (state, action) => {
            state.selectedSector = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShareList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchShareList.fulfilled, (state, action) => {
                state.loading = false;
                state.sectors = action.payload.sectors;
                state.companies = action.payload.companies;
            })
            .addCase(fetchShareList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch data';
            });
    },
});

export const shareListReducer = shareListSlice.reducer;
export const { setScrollTop, setSelectedCategory, setSelectedSector } = shareListSlice.actions;
