import { lazy } from 'react';

export const MarketMap = lazy(() => import('../pages/market-map/market-map'));
export const Glossary = lazy(() => import('../pages/glossary/glossary'));
export const ScreenerMain = lazy(() => import('../pages/screener/ui/screener-main/screener-main'));
export const TariffsPage = lazy(() => import('../pages/tariffs-page/tariffs-page'));
export const AnalyticsAndNews = lazy(() => import('../pages/analytics-and-news/analytics-and-news'));
export const LayoutMain = lazy(() => import('./layout-main/layout-main'));
export const AppMain = lazy(() => import('../pages/app-main/app-main'));
export const AuthorizationPage = lazy(() => import('../pages/app-authorization/app-authorization-page'));
export const AboutCard = lazy(() => import('../pages/analytics-and-news/about-card/about-card'));
export const Education = lazy(() => import('../pages/education/education'));
export const LessonsPage = lazy(() => import('../pages/education/lessons-page/lessons-page'));
export const Lesson = lazy(() => import('../pages/education/lesson/lesson'));
export const Tables = lazy(() => import('../pages/tables/tables-column'));
export const PageInfo = lazy(() => import('../pages/page-info/page-info'));
export const Profile = lazy(() => import('../pages/profile/app-main-profile'));
export const PageInfoUrl = lazy(() => import('../../components/pages/page-info/page-info-url/page-url-info'));
export const ProductSub = lazy(() => import('../../components/pages/products/product-sub-url/product-sub-url'));
export const NoAccess = lazy(() => import('../pages/no-access-page/no-access-page'));
export const Error500 = lazy(() => import('../pages/error-pages/error-500/error-500'));
export const Error403 = lazy(() => import('../pages/error-pages/error-403/error-403'));
export const Webinars = lazy(() => import('../pages/webinars/webinars'));
export const AboutUs = lazy(() => import('../pages/about-us/about-us'));
export const TableNew = lazy(() => import('../pages/tables/table-new/table-new'));
export const NoAuthProfile = lazy(() => import('../pages/profile/no-auth-profile/no-auth-profile'));
export const ScreenerPage = lazy(() => import('../pages/screener/screener-page'));
export const BlogPage = lazy(() => import('../pages/blog-page/blog-page'));
export const BlogForLink = lazy(() => import('../pages/blog-page/ui/blog-for-link/blog-for-link'));
export const PageForAddInfo = lazy(() => import('../pages/page-for-add-info/page-for-add-info'));
